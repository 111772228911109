/**
 =========================================================
 * Soft UI Dashboard PRO React - v4.0.0
 =========================================================

 * Product Page: https://material-ui.com/store/items/soft-ui-pro-dashboard/
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, { useContext, useEffect } from "react";

// react-router components
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Soft UI Dashboard PRO React components

// Soft UI Dashboard PRO React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Soft UI Dashboard PRO React themes
import theme from "assets/theme";

// RTL plugins
// Soft UI Dashboard PRO React routes
import routes from "routes";

// Soft UI Dashboard PRO React contexts
import { setOpenConfigurator, useSoftUIController } from "context";

// Images
import brand from "assets/images/logo-mini.png";
import BasicLogin from "./layouts/authentication/login/index";
import { AuthContext } from "./AuthContext";
import { NotificationProvider } from "./shared/context/notificationContext";
import Notification from "./shared/components/Notification/Notification";
import { LoaderProvider } from "./shared/context/loaderContext";
import Loader from "./shared/components/Loader/Loader";
import ProtectedRoute from "./shared/utils/_protectedRoute";
import Redirect from "layouts/authentication/redirect/redirect";
import { UserDataProvider } from "shared/context/userDataContext";
import { AppConfigurationContextProvider } from "./shared/context/configurationContext";

export default function App() {
  const [controller] = useSoftUIController();
  const { direction, layout, openConfigurator, sidenavColor } = controller;
  const { pathname } = useLocation();
  const { platform } = useContext(AuthContext);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route, index) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.visibleForBrand) {
        if (route.visibleForBrand !== platform) {
          return;
        }
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={<ProtectedRoute>{route.component}</ProtectedRoute>}
            key={route.key || index}
          />
        );
      }

      return null;
    });

  return (
    <UserDataProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={brand}
              brandName="eShop Portal 2"
              routes={routes}
            />
            {/*<Configurator/>*/}
            {/*{configsButton}*/}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <LoaderProvider>
          <Loader />
          <NotificationProvider>
            <AppConfigurationContextProvider>
              <Routes>
                {getRoutes(routes)}
                <Route
                  path="/api/connect/microsoft/redirect"
                  element={<Redirect provider="microsoft" />}
                />
                <Route path="/login" element={<BasicLogin />} />
                <Route path="/ea-sign-in" element={<BasicLogin />} />
                <Route path="*" element={<Navigate to="/dashboard" />} />
              </Routes>
              <Notification />
            </AppConfigurationContextProvider>
          </NotificationProvider>
        </LoaderProvider>
      </ThemeProvider>
    </UserDataProvider>
  );
}
